import React, { FC } from 'react';
import { Box, FormControl, Grid, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import { SegmentedControl } from 'segmented-control';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DateRangeOutlined } from '@material-ui/icons';

import Styles from '../../ContractPaymentScheduleFormMainView.module.scss';

import { IUseFormResult } from '../../../../../shared/customHooks/useForm';
import Typography from '../../../../../shared/components/Typography/Typography';
import TextField from '../../../../../shared/components/TextField/TextField';
import usePaymentDateType from '../../../Core/customHooks/usePaymentDateType';
import { ContractPaymentSchedule, Event, ScheduleDate } from '../../../../../models/ContractPayments';
import { DEFAULT_DATE_FORMAT } from '../../../../../helpers/dates';
import { IPaymentDate } from '../../../Core/interfaces/IPaymentDate';
import PaymentDateDisplacement from './PaymentDateDisplacement';

interface IPaymentDateType extends Partial<IUseFormResult<ContractPaymentSchedule>> {
  currentContractId?: number;
  currentContractPaymentSchedule: ContractPaymentSchedule | undefined;
}

const PaymentDateType: FC<IPaymentDateType> = ({
  data,
  errors,
  currentContractPaymentSchedule,
  handleChangeData,
  currentContractId,
}) => {
  const {
    eventList,
    autocompleteEventValue,
    setAutocompleteEventValue,
    inputEventValue,
    setInputEventValue,
    scheduleList,
    autocompleteScheduleValue,
    setAutocompleteScheduleValue,
    inputScheduleValue,
    setInputScheduleValue,
    paymentDateTypesOptions,
    handleChangeDateValue,
    handleChangeDisplacement,
    maxValidDate,
    minValidDate,
    initialPaymentDate
  } = usePaymentDateType({ 
    currentContractPaymentSchedule, 
    handleChangeData, 
    data, 
    currentContractId, 
  });

  return (
    <>
      <input name="scheduleLabel" value={data?.scheduleLabel || ''} id="scheduleLabel" type="hidden" />
      <Box display="flex" justifyContent="center">
        <Grid item xs={10}>
          <SegmentedControl
            className={Styles.switch}
            name="paymentDateType"
            options={paymentDateTypesOptions}
            setValue={(value: string) => {
              const paymentDate: IPaymentDate = {
                customDate: null,
                type: value,
              };
              if (value !== data?.paymentDateType) {
                handleChangeDateValue('paymentDateType', paymentDate);
              }
            }}
            variant="base"
          />
        </Grid>
      </Box>
      {(() => {
        switch (data?.paymentDateType) {
          case 'EVENT':
            return (
              <>
                <Box mx={5} my={1}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="paymentEvent"
                      options={eventList as Event[]}
                      blurOnSelect
                      getOptionLabel={(option: Event) => `${option.description}`}
                      classes={{
                        option: Styles.autocompleteOption,
                      }}
                      value={autocompleteEventValue}
                      onChange={(event, newValue) => {
                        const paymentDate: IPaymentDate = {
                          customDate: newValue?.customDate || null,
                          id: newValue?.id || undefined,
                          description: newValue?.description || undefined,
                        };
                        handleChangeDateValue('eventId', paymentDate);
                        setAutocompleteEventValue(newValue);
                      }}
                      inputValue={inputEventValue}
                      onInputChange={(_, newInputValue) => {
                        setInputEventValue(newInputValue);
                      }}
                      renderOption={(option: Event) => (
                        <React.Fragment>
                          <div className={Styles.autocompleteOptionText}>
                            <Typography variant="button">{option.description}</Typography> <br />
                            <Typography variant="caption">
                              ({moment(Number(option.customDate)).format(DEFAULT_DATE_FORMAT).toString()})
                            </Typography>
                          </div>
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Hito"
                          variant="outlined"
                          fullWidth
                          size="small"
                          name="eventId"
                          helperText={errors?.eventId}
                          error={!!errors?.eventId}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
                <PaymentDateDisplacement
                  handleChangeDisplacement={handleChangeDisplacement}
                  data={data}
                  initialPaymentDate={initialPaymentDate || 0}
                  errors={errors}
                />
              </>
            );
          case 'SCHEDULE':
            return (
              <>
                <Box mx={5} my={1}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="paymentSchedule"
                      options={scheduleList as ScheduleDate[]}
                      blurOnSelect
                      getOptionLabel={(option: ScheduleDate) => `${option.name}`}
                      classes={{
                        option: Styles.autocompleteOption,
                      }}
                      value={autocompleteScheduleValue}
                      onChange={(event, newValue) => {
                        const paymentDate: IPaymentDate = {
                          customDate: newValue?.customDate || null,
                          id: newValue?.scheduleId || undefined,
                          label: newValue?.id || undefined,
                          description: newValue?.name || undefined,
                        };

                        handleChangeDateValue('scheduleId', paymentDate);
                        setAutocompleteScheduleValue(newValue);
                      }}
                      inputValue={inputScheduleValue}
                      onInputChange={(_, newInputValue) => {
                        setInputScheduleValue(newInputValue);
                      }}
                      renderOption={(option: ScheduleDate) => (
                        <React.Fragment>
                          <div className={Styles.autocompleteOptionText}>
                            <Typography variant="button">{option.name}</Typography> <br />
                            <Typography variant="caption">
                              ({moment(Number(option.customDate)).format(DEFAULT_DATE_FORMAT).toString()})
                            </Typography>
                          </div>
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Evento"
                          variant="outlined"
                          fullWidth
                          size="small"
                          name="scheduleId"
                          helperText={errors?.scheduleId}
                          error={!!errors?.scheduleId}
                        />
                      )}
                    />
                  </FormControl>
                </Box>
                <PaymentDateDisplacement
                  handleChangeDisplacement={handleChangeDisplacement}
                  data={data}
                  initialPaymentDate={initialPaymentDate || 0}
                  errors={errors}
                />
              </>
            );
          default:
            return (
              <Box mx={5} my={1}>
                <Grid container direction="row" alignItems="center" spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1">Fecha</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                          value={data?.paymentDate ? moment(Number(data?.paymentDate)) : null}
                          format="DD/MM/YYYY"
                          minDate={moment(Number(minValidDate))}
                          maxDate={moment(Number(maxValidDate))}
                          onChange={(date) => {
                            const paymentDate: IPaymentDate = {
                              customDate: date?.valueOf() || null,
                            };

                            handleChangeDateValue('paymentDate', paymentDate);
                          }}
                          TextFieldComponent={(props) => (
                            <TextField
                              {...props}
                              label=""
                              variant="outlined"
                              fullWidth
                              size="small"
                              helperText={errors?.paymentDate}
                              error={!!errors?.paymentDate}
                              value={data?.paymentDate ? moment(Number(data?.paymentDate)).format("DD/MM/YYYY") : null}
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <DateRangeOutlined />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            );
        }
      })()}
    </>
  );
};

export default PaymentDateType;
